import { NavLink } from "react-router-dom";

/* This example requires Tailwind CSS v2.0+ */
export const navigationItems = [
  { name: "Home", href: "/" },
  { name: "Portfolio", href: "/portfolio" },
  { name: "Contact", href: "/contact" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HeaderNavLink = ({ item }) => {
  return (
    <NavLink
      to={item.href}
      key={item.href}
      className={({ isActive }) =>
        classNames(
          isActive
            ? "bg-gray-900 text-white"
            : "text-gray-300 hover:bg-gray-700 hover:text-white",
          "px-3 py-2 rounded-md text-sm font-medium"
        )
      }
    >
      {item.name}
    </NavLink>
  );
};

export const Header = ({ showName }) => {
  return (
    <header className="bg-gray-800">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-orange-500 lg:border-none">
          <div className="flex items-center">
            <a href="/">
              <span className="sr-only">Matthew A. Smith</span>
              <img
                className="h-10 w-auto rounded-full"
                src="/profile-mas.jpg"
                alt=""
              />
            </a>
            {showName ? (
              <h1 className="text-white text-2xl font-bold mx-4">
                Matthew A. Smith
              </h1>
            ) : (
              ""
            )}

            <div className="hidden ml-10 space-x-8 lg:block">
              {navigationItems.map((link) => (
                <HeaderNavLink item={link} />
              ))}
            </div>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigationItems.map((link) => (
            <HeaderNavLink item={link} />
          ))}
        </div>
      </nav>
    </header>
  );
};
