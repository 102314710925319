import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Contact } from "./components/Contact";
import { Home } from "./components/Home";
import { Portfolio } from "./components/Portfolio";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  );
}
