export const portfolioData = [
  {
    title: "Pathstream",
    href: "https://www.pathstream.com",
    category: { name: "Software" },
    description:
      "Focused on building internal and external tools that help students develop digital skills in a hands-on environment.",
    date: "2020",
    datetime: "2020-07-01",
    imageUrl: "/mars-IgUR1iX0mqM-unsplash.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Senior Software Engineer",
    },
  },
  {
    title: "Xcode Metrics Organizer",
    href: "https://developer.apple.com/documentation/xcode/improving_your_app_s_performance",
    category: { name: "Software" },
    description:
      "Major contributor to the UI/UX design of the Xcode Metrics Organizer that allows App Store developers to visualize end-user performance metrics.",
    date: "2019",
    datetime: "2019-06-01",
    imageUrl: "/xmo-edited.png",
    imageClassName: "object-cover",
    author: {
      name: "UI/UX Design, Software Engineering",
    },
  },
  {
    title: "Passbook (Wallet)",
    href: "https://download.developer.apple.com/ios/passbook_support_materials/passbook_materials.dmg",
    category: { name: "Software" },
    description:
      "Helped with the bring-up of Passbook (now Wallet) on iOS. Wrote the first Passbook server that was distributed as sample code.",
    date: "2013",
    datetime: "2013-06-01",
    imageUrl: "/passbook.png",
    imageClassName: "object-cover",
    author: {
      name: "QA, Software Engineering",
    },
  },
  {
    title: "Clocked",
    href: "https://clocked.me",
    category: { name: "iOS App" },
    description:
      "A React-Native iOS app, backed by Firebase, for keeping tabs on your favorite Drag Performers.",
    date: "2020",
    datetime: "2020-06-01",
    imageUrl: "/clocked.png",
    imageClassName: "object-cover",
    author: {
      name: "Creator & Developer",
    },
  },
  {
    title: "San Francisco Pride",
    href: "https://www.sfpride.org",
    category: { name: "Event Production" },
    description:
      "Responsible for logistics and fulfillment for an event that generates $500,000 in revenue. The program involves local non-profits and provides them a revenue earning opportunity.",
    date: "2014 - 2017",
    datetime: "2014-07-16",
    imageUrl: "/pride.png",
    imageClassName: "object-cover",
    author: {
      name: "Beverages Manager",
    },
  },
  {
    title: "The Awesome 80's Prom",
    href: "https://en.wikipedia.org/wiki/The_Awesome_80s_Prom",
    category: { name: "Theater" },
    description:
      "Setup/Breakdown of scenic elements and props. Seat guests at reserved tables. Supervise stage activities and act as security for actors while extending the interactive environment.",
    date: "2006 - 2009",
    datetime: "2006-07-16",
    imageUrl: "/Awesome_80s_Prom.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Props Master & Assistant Stage Manager",
    },
  },
  {
    title: "My First Time",
    href: "https://en.wikipedia.org/wiki/My_First_Time_(play)",
    category: { name: "Theater" },
    description:
      "Developed projection materials, designed lights, sound, projections, and coordinated production's needs for an open-ended run Off-Broadway.",
    date: "2007 - 2009",
    datetime: "2007-07-16",
    imageUrl: "/Myfirsttimeplay.jpg",
    imageClassName: "object-cover object-[50%_75%]",
    author: {
      name: "Production Designer",
    },
  },
  {
    title: "Times Square New Year's Eve",
    href: "https://en.wikipedia.org/wiki/Times_Square_Ball",
    category: { name: "Event Production" },
    description:
      "Assisted the Production Manager in day-to-day operations, petty cash accounting, coordination of VIP Party, and runner for performing talent.",
    date: "2005",
    datetime: "2004-12-31",
    imageUrl: "/luo-lei-HbNCZ0k-txA-unsplash.jpg",
    imageClassName: "object-cover",
    author: {
      name: "Production Assistant",
    },
  },
];

// eslint-disable-next-line no-unused-vars
const example = {
  title: "",
  href: "",
  category: { name: "Event" },
  description: "",
  date: "2005",
  datetime: "2005-06-01",
  imageUrl: "",
  imageClassName: "object-cover",
  author: {
    name: "",
  },
};
